// extracted by mini-css-extract-plugin
export var actualLink = "styles-module--actualLink--12YNb";
export var fauxLink = "styles-module--fauxLink--1QOlX";
export var textWidth = "styles-module--textWidth--uh5Ru";
export var pageHeader = "styles-module--pageHeader--3lW_1";
export var pageTitle = "styles-module--pageTitle--3hA7a";
export var pageSubTitle = "styles-module--pageSubTitle--kAqcR";
export var unset = "styles-module--unset--z8aly";
export var resetList = "styles-module--resetList--1Jg6r";
export var tac = "styles-module--tac--1PJlx";
export var tal = "styles-module--tal--3Y4-i";
export var tar = "styles-module--tar--ubeCi";
export var noBoxShadow = "styles-module--no-box-shadow--chakC";
export var ptn = "styles-module--ptn--gyWT6";
export var pvn = "styles-module--pvn--1u4I4";
export var pan = "styles-module--pan--3hTXv";
export var ptxs = "styles-module--ptxs--3MzWO";
export var pvxs = "styles-module--pvxs--3_2iW";
export var paxs = "styles-module--paxs--Z4eF6";
export var pts = "styles-module--pts--3teac";
export var pvs = "styles-module--pvs--ElCK0";
export var pas = "styles-module--pas--fZq20";
export var ptm = "styles-module--ptm--2nxz1";
export var pvm = "styles-module--pvm--_f2HF";
export var pam = "styles-module--pam--2P1tL";
export var ptl = "styles-module--ptl--1wLWl";
export var pvl = "styles-module--pvl--FY8-t";
export var pal = "styles-module--pal--2rLQ5";
export var ptxl = "styles-module--ptxl--3HjPX";
export var pvxl = "styles-module--pvxl--2pw5f";
export var paxl = "styles-module--paxl--3K962";
export var prn = "styles-module--prn--wFbm-";
export var phn = "styles-module--phn--3st4g";
export var prxs = "styles-module--prxs--3cb_Y";
export var phxs = "styles-module--phxs--CTqBc";
export var prs = "styles-module--prs--1EB8K";
export var phs = "styles-module--phs--3nSR_";
export var prm = "styles-module--prm--pqKhG";
export var phm = "styles-module--phm--2UwPE";
export var prl = "styles-module--prl--2aADw";
export var phl = "styles-module--phl--2w8-Q";
export var prxl = "styles-module--prxl--1KKol";
export var phxl = "styles-module--phxl--21uYF";
export var pbn = "styles-module--pbn--1heyU";
export var pbxs = "styles-module--pbxs--Qo5GN";
export var pbs = "styles-module--pbs--1BrWF";
export var pbm = "styles-module--pbm--3aM3E";
export var pbl = "styles-module--pbl--3OmTw";
export var pbxl = "styles-module--pbxl--3-iPj";
export var pln = "styles-module--pln--1ObRk";
export var plxs = "styles-module--plxs--2aVIU";
export var pls = "styles-module--pls--9BdPX";
export var plm = "styles-module--plm--1v0DR";
export var pll = "styles-module--pll--2FvaH";
export var plxl = "styles-module--plxl--BS8oA";
export var mtn = "styles-module--mtn--2TycC";
export var mvn = "styles-module--mvn--12PIp";
export var man = "styles-module--man--11Fbz";
export var mtxs = "styles-module--mtxs--2aToq";
export var mvxs = "styles-module--mvxs--CzC7-";
export var maxs = "styles-module--maxs--30I2g";
export var mts = "styles-module--mts--36yAo";
export var mvs = "styles-module--mvs--3_2V5";
export var mas = "styles-module--mas--1xU88";
export var mtm = "styles-module--mtm--37kc5";
export var mvm = "styles-module--mvm--3-Cio";
export var mam = "styles-module--mam--3dgEm";
export var mtl = "styles-module--mtl--2BYh8";
export var mvl = "styles-module--mvl--3BHTg";
export var mal = "styles-module--mal--3YFsv";
export var mtxl = "styles-module--mtxl--98KTs";
export var mvxl = "styles-module--mvxl--_sliJ";
export var maxl = "styles-module--maxl--1SMCe";
export var mrn = "styles-module--mrn--b-b9U";
export var mhn = "styles-module--mhn--8mnir";
export var mrxs = "styles-module--mrxs--2mriQ";
export var mhxs = "styles-module--mhxs--1F4VN";
export var mrs = "styles-module--mrs--9zorC";
export var mhs = "styles-module--mhs--3Ql1y";
export var mrm = "styles-module--mrm--lfg8c";
export var mhm = "styles-module--mhm--1LSKd";
export var mrl = "styles-module--mrl--ex04N";
export var mhl = "styles-module--mhl--ECumP";
export var mrxl = "styles-module--mrxl--150sw";
export var mhxl = "styles-module--mhxl--1U7vl";
export var mbn = "styles-module--mbn--16WVX";
export var mbxs = "styles-module--mbxs--35NrK";
export var mbs = "styles-module--mbs--ki_X4";
export var mbm = "styles-module--mbm--2yT3z";
export var mbl = "styles-module--mbl--3HJvE";
export var mbxl = "styles-module--mbxl--1LMNS";
export var mln = "styles-module--mln---3Ms5";
export var mlxs = "styles-module--mlxs--2dSHM";
export var mls = "styles-module--mls--1pRng";
export var mlm = "styles-module--mlm--22gNe";
export var mll = "styles-module--mll--dOdCM";
export var mlxl = "styles-module--mlxl--AD2bi";
export var articlesWrapper = "styles-module--articlesWrapper--2c9Py";